<template>
  <img
    v-if="user"
    class="rounded-circle"
    :src="user.avatarUrl"
    :alt="`Profile ${user.username}`"
    :width="width"
    :height="height"
  />
</template>

<script lang="ts">
export default defineComponent({
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
  },
  setup() {
    const authStore = useAuthStore();
    const user = computed(() => authStore.user);

    return { user };
  },
});
</script>

<style scoped></style>
